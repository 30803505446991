import React, { useEffect, useState } from 'react';
import bannerImage1 from '../assets/img/banner/banner-mahalakshmi.jpeg'
import { Link } from 'react-router-dom'


export default function ProjectPopup() {

    const [showPopup, setShowPopup] = useState(false);
    const [showCount, setShowCount] = useState(0);
    const [timer, setTimer] = useState(null);
  
    useEffect(() => {
      // Show the popup after 4 seconds when the component mounts
      const firstTimer = setTimeout(() => {
        setShowPopup(true);
      }, 4000);
  
      return () => clearTimeout(firstTimer);
    }, []);
  
    useEffect(() => {
      // If the popup has been closed 3 times, do not show it again
      if (showCount >= 3) return;
  
      // Set the timer to show the popup again after 8 seconds if it was closed
      if (!showPopup && showCount > 0) {
        const nextTimer = setTimeout(() => {
          setShowPopup(true);
        }, 8000);
        setTimer(nextTimer);
      }
  
      return () => clearTimeout(timer);
    }, [showPopup, showCount]);
  
    const handleKnowMore = () => {
      setShowPopup(false);
      // Optionally add more logic for the "Know More" button
    };
  
    const handleClose = () => {
      setShowPopup(false);
      setShowCount((prevCount) => prevCount + 1);
    };
  
  return (
    <div>
      {showPopup && (
        <div
          className="modal d-flex justify-content-center align-items-center"
          tabIndex="-1"
          role="dialog"
          style={{
            display: 'block',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: '99999999999999999999999999 !important', // Bootstrap modal default is 1050, so using this for consistency
          }}
        >
           <div 
            className="modal-dialog" 
            role="document" 
            style={{
              width: '90vw',
              maxWidth: '600px', // Maximum width for larger screens
              height: '90vh',
              maxHeight: '600px', // Maximum height for larger screens
              '@media (min-width: 768px)': {
                width: '55vw',
                height: '55vh',
              }
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
              </div>
              <div className="modal-body p-0">
                <img
                  src={bannerImage1}
                  alt="Banner 1"
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
              </div>
              <div className="modal-footer">
              <a href="https://www.shreemahalakshmisweets.com/" target="_blank" rel="noopener noreferrer">
  <button
    style={{
      background: 'radial-gradient(84.91% 87.64% at 62.75% 25.51%, rgb(246, 159, 0) 35.04%, rgb(230, 40, 15) 94.85%)',
      color: '#fff',
      fontWeight: '500'
    }}
    type="button"
    className="btn px-4"
    onClick={handleKnowMore}
  >
    Enquire Now
  </button>
</a>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
